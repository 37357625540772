import { ReactElement } from 'react';
import cn from 'classnames';

import styles from './Icon.module.scss';

import { ReactComponent as Alert } from '../../images/vectors/alert.svg';
import { ReactComponent as Basket } from '../../images/vectors/basket.svg';
import { ReactComponent as Checked } from '../../images/vectors/checked.svg';
import { ReactComponent as Link } from '../../images/vectors/link.svg';
import { ReactComponent as Mail } from '../../images/vectors/mail.svg';
import { ReactComponent as Next } from '../../images/vectors/next.svg';
import { ReactComponent as Phone } from '../../images/vectors/phone.svg';
import { ReactComponent as Play } from '../../images/vectors/play.svg';
import { ReactComponent as Edit } from '../../images/vectors/edit.svg';
import { ReactComponent as Download } from '../../images/vectors/download.svg';
import { ReactComponent as Plus } from '../../images/vectors/plus.svg';
import { ReactComponent as Document } from '../../images/vectors/document.svg';
import { ReactComponent as Good } from '../../images/vectors/good.svg';
import { ReactComponent as Neutral } from '../../images/vectors/neutral.svg';
import { ReactComponent as Bad } from '../../images/vectors/bad.svg';
import { ReactComponent as Refresh } from '../../images/vectors/refresh.svg';
import { ReactComponent as Search } from '../../images/vectors/search.svg';

export interface IconProps {
    name:
        | 'alert'
        | 'basket'
        | 'checked'
        | 'link'
        | 'mail'
        | 'next'
        | 'phone'
        | 'play'
        | 'edit'
        | 'download'
        | 'plus'
        | 'document'
        | 'good'
        | 'neutral'
        | 'bad'
        | 'refresh'
        | 'search';
    size?: 'small' | 'medium' | 'large';
    className?: string;
}

export const Icon = ({ name, size, className }: IconProps): ReactElement | null => {
    const renderIcon = () => {
        switch (name) {
            case 'alert':
                return <Alert />;
            case 'basket':
                return <Basket />;
            case 'checked':
                return <Checked />;
            case 'link':
                return <Link />;
            case 'mail':
                return <Mail />;
            case 'next':
                return <Next />;
            case 'phone':
                return <Phone />;
            case 'play':
                return <Play />;
            case 'edit':
                return <Edit />;
            case 'download':
                return <Download />;
            case 'plus':
                return <Plus />;
            case 'document':
                return <Document />;
            case 'good':
                return <Good />;
            case 'neutral':
                return <Neutral />;
            case 'bad':
                return <Bad />;
            case 'refresh':
                return <Refresh />;
            case 'search':
                return <Search />;
            default:
                return <Alert />;
        }
    };

    return <div className={cn(styles.wrapper, size && styles[size], className)}>{renderIcon()}</div>;
};
